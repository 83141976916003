import { useEffect, useState, ReactElement } from "react";
import styles from "./style.module.scss";

import colors from "@styles/variables/injurycompensationVariables.module.scss";

import { getDomainForm } from "src/api";
import { useDomainContext } from "@hooks/useDomainContext";
import Form from "@components/shared/form";
import ProgressBar from "@components/shared/form/progressBar";

import CampIcon from "./icons/camp";
import TalcumIcon from "./icons/talcum";
import FormAutoAccidentIcon from "./icons/accident";
import SlipFallIcon from "./icons/slip_fall";
import OnTheJobIcon from "./icons/on_the_job";
import ZantacIcon from "./icons/zantac";

const injuryType = [
    {
        category: "auto-accident",
        icon: <FormAutoAccidentIcon />,
        label: "Auto Accident",
    },
    {
        category: "personal-injury",
        icon: <SlipFallIcon />,
        label: "Slip & Fall",
    },

    {
        category: "worker-compensation",
        icon: <OnTheJobIcon />,
        label: "Hurt at Work",
    },
    {
        category: "camp-lejeune",
        icon: <CampIcon />,
        label: "Camp Lejeune",
    },
    {
        category: "talcum-powder",
        icon: <TalcumIcon />,
        label: "Talcum Powder",
    },
    {
        category: "zantac",
        icon: <ZantacIcon />,
        label: "Zantac",
    },
];

export default function InjuryType(): ReactElement {
    const { updateForm, visitDetails, formattedTitle, form } =
        useDomainContext();
    const [currentCategory, updateCurrentCategory] = useState(undefined);
    const [isFormFetched, updateFormFetched] = useState(false);
    const [currentStep, updateCurrentStep] = useState(0);
    const [formError, updateFetchFormError] = useState(null);
    const getForm = async (categorySlug: string) => {
        const { data, error } = await getDomainForm({
            categorySlug,
        });
        if (error) {
            updateFetchFormError(error);
        }
        if (data) {
            updateFetchFormError(null);
            updateForm(data);
            updateFormFetched(true); // display form component
            updateCurrentStep(1); // move next step
        }
    };
    useEffect(() => {
        if (currentCategory) {
            void getForm(currentCategory);
        }
    }, [currentCategory]);

    const formContent = isFormFetched ? (
        <div
            className={`${styles.formOtherSteps} ${
                currentCategory ? styles[currentCategory] : ""
            }`}
        >
            <Form
                classes={{
                    formClassName: `${styles["form"]}`,
                    stepTitleClassName: styles["step-title"],
                    stepClassName: styles["step-block"],
                    stepDescriptionClassName: styles["step-description"],
                    formButtonClassName: styles["form-button"],
                    formFieldClassName: styles["form-field"],
                    formFieldLabelClassName: styles["field-label"],
                    formStepInnerClassName: styles["steps-inner"],
                    fieldsClassName: {
                        radio: styles["radio-field"],
                    },
                    fieldsWrapperClassName: styles["fields-wrapper"],
                    tcpaClassName: styles["tcpa"],
                    labelAsTitleClassName: styles["label-as-step-title"],
                    formSubmitButtonClassName: styles["submit-button"],
                    sellingPointClassName: styles["selling-point"],
                    formProgressbarClassName: styles["custom-progress"],
                }}
                colors={{
                    primaryColor: colors.lcPrimary,
                }}
                showProgress={true}
                progressShift={1}
                backButton={{
                    class: styles["back-button"],
                    label: "Back",
                    firstStepAction: () => {
                        updateCurrentCategory(undefined);
                        updateCurrentStep(0);
                    },
                }}
                preventWindowClose
                stepsConfig={[
                    {
                        stepOrder: 0,
                        hideTitle: true,
                        hideDescription: false,
                        forceStepTitle: true,
                    },
                ]}
                currentCategorySlug={currentCategory}
                goToThankYouPage={
                    currentCategory === "auto-accident" ? true : false
                }
            />
        </div>
    ) : null;

    return (
        <div className={`${styles.mainBanner} `}>
            <div className={`${styles.mainBannerWrapper}`}>
                <div className={`${styles.formWrapper}`}>
                    <div className={`${styles.formStepsWrapper}`}>
                        <div
                            style={{
                                transform: `translate(${
                                    currentStep * -100
                                }%, 0)`,
                            }}
                            className={`${styles.formStepsWrapperWindow}`}
                        >
                            <div className={`${styles.stepBlock}`}>
                                <div>
                                    <h1
                                        className={`${styles.outsideTitle}  max-w-xl mx-auto px-3.5 md:px-0 text-center mb-4`}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                formattedTitle ??
                                                "Suffered an Injury from an Accident or Pharmaceutical Product? You May Be Entitled to Financial Compensation",
                                        }}
                                    ></h1>
                                    <p
                                        className={`${styles["outsideBrief"]} font-bold  px-3.5 md:px-0 mx-auto mb-8 text-base md:text-xl text-center`}
                                    >
                                        What caused you or your loved one an
                                        injury or an illness?
                                    </p>
                                </div>
                                {formError && (
                                    <div className={styles.errorMessage}>
                                        Something went wrong
                                    </div>
                                )}

                                <div
                                    className={`${styles.serviceBlocks} flex text-lg mx-auto`}
                                >
                                    {injuryType.map((item) => {
                                        return (
                                            <label
                                                key={item.label}
                                                className={`${styles.serviceBlockLnk}  flex flex-col items-center justify-center text-center  rounded-lg mb-4  mx-2`}
                                                onClick={() =>
                                                    updateCurrentCategory(
                                                        item.category,
                                                    )
                                                }
                                            >
                                                <span
                                                    className={`block ${
                                                        styles["icon"]
                                                    } ${styles[item.category]}`}
                                                >
                                                    {item.icon}
                                                </span>
                                                <span
                                                    className={`text-base  block font-bold w-full`}
                                                >
                                                    {item.label}
                                                </span>
                                            </label>
                                        );
                                    })}
                                </div>
                                <div
                                    className={`${styles["region"]}`}
                                >{`${visitDetails?.region} Best Car Accident Lawyers`}</div>
                                <ProgressBar
                                    className={`${styles["progress-bar"]} ${styles["custom-progress"]}`}
                                    current={0}
                                    total={8}
                                    color={colors?.lcPrimary}
                                />
                            </div>
                            <div
                                key={form?.id}
                                className={`${styles.stepBlock}`}
                            >
                                {formContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { ReactElement } from "react";

export default function CampIcon(): ReactElement {
    return (
        <svg
            width="64"
            height="59"
            viewBox="0 0 64 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M62.5796 59.0001H0.716068C0.315105 59.0001 0 58.685 0 58.2841V38.2785C0 32.7937 2.14819 27.6243 6.05759 23.7148C9.96698 19.8054 15.1367 17.6572 20.6213 17.6572H42.674C48.1589 17.6572 53.3282 19.8054 57.2377 23.7148C61.1476 27.6242 63.2958 32.7935 63.2958 38.2785V58.2841C63.2958 58.6707 62.9807 59.0001 62.5797 59.0001H62.5796ZM1.43183 57.568H61.8639V38.2794C61.8639 27.6967 53.2572 19.0909 42.6753 19.0909L20.6226 19.0904C10.0399 19.0904 1.43413 27.6971 1.43413 38.279L1.43183 57.568Z"
                fill="black"
            />
            <path
                d="M37.634 59.0003C37.233 59.0003 36.9179 58.6852 36.9179 58.2842V41.3154C36.9179 40.7999 36.4882 40.3701 35.9726 40.3701H27.3232C26.8077 40.3701 26.3779 40.7999 26.3779 41.3154V58.2842C26.3779 58.6852 26.0628 59.0003 25.6619 59.0003C25.2609 59.0003 24.9458 58.6852 24.9458 58.2842L24.9463 41.3154C24.9463 40.0123 26.0057 38.9385 27.3232 38.9385H35.9726C37.2757 38.9385 38.3496 39.998 38.3496 41.3154V58.2842C38.3496 58.6708 38.0349 59.0003 37.6339 59.0003H37.634Z"
                fill="black"
            />
            <path
                d="M18.0864 43.1036H9.82351C8.4058 43.1036 7.26001 41.9578 7.26001 40.5262V32.1343C7.26001 30.7166 8.41972 29.5708 9.82351 29.5708H18.0864C19.5041 29.5708 20.6499 30.7305 20.6499 32.1343L20.6494 40.5257C20.6638 41.9434 19.5041 43.1036 18.0864 43.1036H18.0864ZM9.82351 31.0024C9.19329 31.0024 8.6921 31.518 8.6921 32.1482V40.5252C8.6921 41.1554 9.20769 41.671 9.82351 41.671H18.0864C18.7166 41.671 19.2178 41.1554 19.2178 40.5252V32.1334C19.2178 31.5032 18.7022 30.9876 18.0864 30.9876L9.82351 30.988V31.0024Z"
                fill="black"
            />
            <path
                d="M53.4715 43.1036H45.2086C43.7909 43.1036 42.6312 41.9578 42.6312 40.5262V32.1343C42.6312 30.7166 43.777 29.5708 45.2086 29.5708H53.4715C54.8892 29.5708 56.0489 30.7305 56.0489 32.1343L56.0494 40.5257C56.0494 41.9434 54.8892 43.1036 53.4715 43.1036H53.4715ZM45.2086 31.0024C44.5784 31.0024 44.0629 31.518 44.0629 32.1482L44.0633 40.5252C44.0633 41.1554 44.5789 41.671 45.2091 41.671H53.472C54.1022 41.671 54.6177 41.1554 54.6177 40.5252V32.1334C54.6177 31.5032 54.1022 30.9876 53.472 30.9876L45.2086 30.988L45.2086 31.0024Z"
                fill="black"
            />
            <path
                d="M31.6478 19.089C31.2469 19.089 30.9318 18.7739 30.9318 18.373V0.716068C30.9318 0.315106 31.2469 0 31.6478 0C32.0488 0 32.3639 0.315106 32.3639 0.716068V18.373C32.3639 18.7739 32.0488 19.0886 31.6478 19.0886V19.089Z"
                fill="black"
            />
            <path
                d="M20.8644 10.6539C19.7905 10.6539 18.6879 10.3964 17.5852 9.68031C17.3847 9.5513 17.2557 9.3225 17.2557 9.07886V1.77572C17.2557 1.51815 17.3991 1.27451 17.6279 1.1455C17.8572 1.0165 18.1435 1.03088 18.3584 1.17427C20.3488 2.47737 22.3968 1.80449 24.5733 1.08842C26.8644 0.343581 29.4568 -0.500979 32.0198 1.17427C32.3493 1.38914 32.4495 1.8328 32.2203 2.16228C32.0054 2.49177 31.5618 2.59201 31.2323 2.36276C29.2418 1.05967 27.1939 1.73255 25.0174 2.44862C23.0553 3.09322 20.8788 3.80879 18.6735 2.94982V8.66346C20.5637 9.69462 22.5114 9.06442 24.5593 8.3915C26.8504 7.64667 29.4428 6.78761 32.0058 8.47736C32.3353 8.69222 32.4355 9.13589 32.2063 9.46537C31.9914 9.79486 31.5477 9.8951 31.2183 9.66585C29.2278 8.36276 27.1799 9.03564 25.0034 9.75171C23.7286 10.1958 22.3109 10.6539 20.8643 10.6539L20.8644 10.6539Z"
                fill="black"
            />
            <path
                d="M27.4373 36.2017C27.2939 36.2017 27.1366 36.1586 27.022 36.0583C26.7927 35.901 26.6925 35.6142 26.7356 35.3423L27.5663 31.0032L24.3443 28.0108C24.1438 27.8247 24.0723 27.524 24.1582 27.266C24.244 27.0084 24.4733 26.8079 24.7452 26.7792L29.127 26.2348L31.0028 22.2396C31.1174 21.982 31.375 21.8242 31.6474 21.8242C31.9194 21.8242 32.1774 21.9815 32.292 22.2396L34.1678 26.2348L38.5496 26.7792C38.8216 26.8079 39.0508 27.0084 39.1367 27.266C39.2225 27.5235 39.151 27.8243 38.9506 28.0108L35.7285 31.0324L36.5592 35.3715C36.6163 35.6435 36.5021 35.9298 36.2729 36.0876C36.0436 36.2449 35.7573 36.2737 35.4997 36.1307L31.633 34.0113L27.7663 36.1451C27.6804 36.1734 27.5519 36.2017 27.4373 36.2017ZM26.4349 28.0108L28.8263 30.2592C29.0124 30.4309 29.0838 30.6746 29.0411 30.9177L28.4253 34.1398L31.3035 32.5647C31.5184 32.4501 31.776 32.4501 31.9908 32.5647L34.8691 34.1398L34.2532 30.9177C34.2101 30.6741 34.282 30.4309 34.4681 30.2592L36.8594 28.0108L33.6086 27.5954C33.365 27.5667 33.1506 27.4093 33.0503 27.1945L31.6614 24.23L30.2724 27.1945C30.1722 27.4237 29.9573 27.5667 29.7141 27.5954L26.4349 28.0108Z"
                fill="black"
            />
            <path
                d="M25.6617 52.3696H0.716068C0.315105 52.3696 0 52.0545 0 51.6536C0 51.2526 0.315105 50.9375 0.716068 50.9375H25.6617C26.0627 50.9375 26.3778 51.2526 26.3778 51.6536C26.3778 52.0545 26.0488 52.3696 25.6617 52.3696Z"
                fill="black"
            />
            <path
                d="M62.5796 52.3696H37.6339C37.233 52.3696 36.9178 52.0545 36.9178 51.6536C36.9178 51.2526 37.233 50.9375 37.6339 50.9375H62.5796C62.9805 50.9375 63.2956 51.2526 63.2956 51.6536C63.2956 52.0545 62.9805 52.3696 62.5796 52.3696Z"
                fill="black"
            />
            <path
                d="M28.3913 30.2592L26 28.0108L29.2792 27.5955C29.5224 27.5667 29.7373 27.4238 29.8375 27.1945L31.2264 24.23L32.6154 27.1945C32.7156 27.4094 32.93 27.5667 33.1737 27.5955L36.4245 28.0108L34.0332 30.2592C33.8471 30.431 33.7751 30.6741 33.8183 30.9178L34.4341 34.1398L31.5559 32.5647C31.341 32.4501 31.0835 32.4501 30.8686 32.5647L27.9904 34.1398L28.6062 30.9178C28.6489 30.6746 28.5774 30.431 28.3913 30.2592Z"
                fill="#BE1E20"
            />
        </svg>
    );
}

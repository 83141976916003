import { ReactElement } from "react";

export default function TalcumIcon(): ReactElement {
    return (
        <svg
            width="87"
            height="51"
            viewBox="0 0 87 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M78.6501 35.1176C76.1744 32.9236 74.5615 29.5039 74.2257 25.7373C73.5608 18.291 70.6915 13.9737 66.1462 13.5828C62.4408 13.2636 60.7882 10.5838 58.8746 7.47955C56.7134 3.97298 54.2641 0 48.5534 0C43.1128 0 39.7272 2.84666 36.7387 5.36004C34.0194 7.6464 31.4514 9.80724 27.5301 9.80724C22.5545 9.80724 16.576 13.1176 12.9912 17.856C10.8875 20.6364 9.80355 23.6184 9.85418 26.4796C9.89305 28.6341 9.16292 30.6389 7.90117 31.8431C1.96502 37.5088 -0.647355 42.1264 0.135485 45.5657C0.773307 48.3676 3.42391 49.9259 8.24053 50.3266C14.3037 50.8314 22.8807 51 32.1522 51C42.9598 51 54.7099 50.7714 64.5087 50.5813C71.4159 50.4459 77.3819 50.3302 81.2554 50.3302C83.3729 50.3302 86.2609 50.0281 86.8868 47.4198C87.3137 45.6498 86.9215 42.4481 78.65 35.1173L78.6501 35.1176ZM84.816 46.5896C84.8139 46.5986 84.5009 47.5266 81.257 47.5266C77.3668 47.5266 71.3938 47.6431 64.4776 47.7785C46.3085 48.1318 21.4285 48.6177 8.38178 47.5302C3.28817 47.1043 2.37474 45.4617 2.21856 44.7739C1.85557 43.1762 3.4963 39.5333 9.19234 34.0961C11.0212 32.3504 12.0796 29.4795 12.0247 26.4148C11.9782 23.7742 13.369 21.3687 14.5441 19.8139C17.7528 15.5721 23.0926 12.6095 27.5319 12.6095C32.0965 12.6095 35.0588 10.1186 37.9238 7.70947C40.9235 5.18645 43.7572 2.80321 48.5552 2.80321C53.2199 2.80321 55.0542 5.78002 57.1794 9.22645C59.1303 12.3915 61.3417 15.9788 66.0028 16.3796C69.4821 16.6791 71.5246 19.9364 72.0715 26.0585C72.4796 30.6322 74.4305 34.7762 77.4233 37.4294C85.2424 44.3576 84.8226 46.5675 84.8162 46.5891L84.816 46.5896Z"
                fill="#444B53"
            />
            <path
                d="M32.8024 19.8197C32.0387 19.944 31.5201 20.6644 31.6444 21.4281L31.6548 21.4918C31.7792 22.2555 32.4995 22.7741 33.2632 22.6498C34.0269 22.5254 34.5456 21.8051 34.4212 21.0414L34.4108 20.9776C34.2853 20.2123 33.5661 19.6953 32.8024 19.8197Z"
                fill="#BE1E20"
            />
            <path
                d="M35.0435 13.974C34.2798 14.0983 33.7612 14.8187 33.8855 15.5824L33.8959 15.6461C34.0203 16.4098 34.7406 16.9284 35.5043 16.8041C36.268 16.6797 36.7866 15.9594 36.6623 15.1957L36.6519 15.1319C36.5275 14.3682 35.8072 13.8496 35.0435 13.974Z"
                fill="#BE1E20"
            />
            <path
                d="M26.0423 16.765C25.2785 16.8894 24.7599 17.6097 24.8843 18.3734L24.8947 18.4371C25.019 19.2009 25.7394 19.7195 26.5031 19.5951C27.2668 19.4707 27.7854 18.7504 27.6611 17.9867L27.6507 17.923C27.5254 17.1594 26.8069 16.6405 26.0423 16.765V16.765Z"
                fill="#BE1E20"
            />
            <path
                d="M18.0464 21.1322C17.2827 21.2565 16.7641 21.9769 16.8884 22.7406L16.8988 22.8043C17.0232 23.568 17.7435 24.0867 18.5073 23.9623C19.271 23.8379 19.7896 23.1176 19.6652 22.3539L19.6548 22.2901C19.5294 21.5257 18.8101 21.0078 18.0464 21.1322Z"
                fill="#BE1E20"
            />
            <path
                d="M14.4 33.3861C13.6363 33.5105 13.1177 34.2308 13.2421 34.9945L13.2525 35.0582C13.3768 35.822 14.0972 36.3406 14.8609 36.2162C15.6246 36.0918 16.1432 35.3715 16.0188 34.6078L16.0085 34.544C15.8841 33.7803 15.1638 33.2617 14.4 33.3861Z"
                fill="#BE1E20"
            />
            <path
                d="M9.38319 41.3797C8.61947 41.5041 8.10088 42.2245 8.22524 42.9882L8.23561 43.0519C8.35998 43.8156 9.08033 44.3342 9.84404 44.2099C10.6078 44.0855 11.1264 43.3651 11.002 42.6014L10.9916 42.5377C10.8664 41.7741 10.1478 41.2552 9.38319 41.3797Z"
                fill="#BE1E20"
            />
            <path
                d="M24.2615 22.5389C23.4978 22.6633 22.9792 23.3836 23.1035 24.1474L23.1139 24.2111C23.2383 24.9748 23.9586 25.4934 24.7223 25.369C25.4861 25.2447 26.0047 24.5243 25.8803 23.7606L25.8699 23.6969C25.7454 22.9323 25.0252 22.4146 24.2615 22.5389Z"
                fill="#BE1E20"
            />
            <path
                d="M22.5822 29.1356C21.8184 29.26 21.2998 29.9803 21.4242 30.744L21.4346 30.8077C21.559 31.5715 22.2793 32.0901 23.043 31.9657C23.8067 31.8413 24.3253 31.121 24.201 30.3573L24.1906 30.2936C24.0653 29.53 23.3459 29.0112 22.5822 29.1356Z"
                fill="#BE1E20"
            />
            <path
                d="M44.0117 6.22545C43.248 6.34981 42.7294 7.07016 42.8538 7.83388L42.8639 7.89582C42.9882 8.65954 43.7086 9.17814 44.4723 9.05378C45.236 8.92942 45.7546 8.20907 45.6303 7.44535L45.6202 7.3834C45.4948 6.61894 44.7755 6.10108 44.0117 6.22545Z"
                fill="#BE1E20"
            />
            <path
                d="M42.3302 12.8221C41.5665 12.9465 41.0479 13.6668 41.1723 14.4306L41.1826 14.4943C41.307 15.258 42.0273 15.7766 42.7911 15.6522C43.5548 15.5279 44.0734 14.8075 43.949 14.0438L43.9386 13.9801C43.8141 13.2155 43.0939 12.6978 42.3302 12.8221Z"
                fill="#BE1E20"
            />
            <path
                d="M15.7341 26.911C14.9704 27.0354 14.4518 27.7557 14.5762 28.5194L14.5866 28.5831C14.7109 29.3469 15.4313 29.8655 16.195 29.7411C16.9587 29.6167 17.4773 28.8964 17.353 28.1327L17.3426 28.0689C17.2182 27.3052 16.4979 26.7866 15.7341 26.911Z"
                fill="#BE1E20"
            />
            <path
                d="M51.0684 10.9432C50.3047 11.0676 49.7861 11.7879 49.9104 12.5516L49.9208 12.6154C50.0452 13.3791 50.7655 13.8977 51.5292 13.7733C52.2929 13.649 52.8115 12.9286 52.6872 12.1649L52.6768 12.1012C52.5523 11.3366 51.833 10.8187 51.0684 10.9432Z"
                fill="#BE1E20"
            />
        </svg>
    );
}

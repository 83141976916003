import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import InjuryCompensationWrapper from "@components/layouts/injuryCompensationWrapper";
import InjuryType from "@components/injurycompensation/injuryType";
const OffersList = dynamic(() => import("@components/shared/offersList"));

const About = dynamic(() => import("@components/injurycompensation/about"));
const BottomSection = dynamic(
    () => import("@components/injurycompensation/bottomSection"),
);
const WhyUs = dynamic(() => import("@components/injurycompensation/whyUs"));
const HowThisWork = dynamic(
    () => import("@components/injurycompensation/howThisWork"),
);

export default function InjuryCompensationHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <InjuryCompensationWrapper>
            <InjuryType />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <HowThisWork />
            <WhyUs />
            <About />
            <BottomSection />
        </InjuryCompensationWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
